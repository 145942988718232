import React, { useState, useEffect } from "react";
import st from "../../../style.module.scss";
import cx from "./Completeprofile.module.scss";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Row, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import bg from "../../../images/bg-img-new.svg";
import logo from "../../../images/logo.png";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import TimezoneSelect, { allTimezones } from 'react-timezone-select'
import { RotatingLines } from "react-loader-spinner";

export default function Teachercompleteprofile(props: any) {

  const location: any = useLocation()
  // let id = location?.state?.detail
  let localData = JSON.parse(localStorage.getItem("userData")!);
  let date = new Date().toISOString();
  let maxDate = date.split("T");

  const navigate = useHistory();

  //----validation----//
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  //---react-toastify---//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);

  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  // const [userid, setUserid] = useState(localData?.id);
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState("");
  const [school_college, setSchool_college] = useState("");
  const [phone, setphone] = useState("");
  const [class_url, setClass_url] = useState("");
  const [teacher_exp, setTeacher_exp] = useState("");
  const [highest_qualification, setHighest_qualification] = useState("");
  const [document, setDocument] = useState("");
  const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [currency, setCurrency] = useState("");
  const [name, setName] = useState("");
  const [DialCode, setDialCode] = useState("");
  const [info, setInfo] = useState('');
  const [abbrev, setAbbrev] = useState('');
  const [loadingSpinner, setLoadingSpinner] = useState<any>('Next');

  function phoneInput(value: any, data: any) {
    const dialCode = `+${data.dialCode}`;
    const mobileNumber = value?.substring(data?.dialCode?.length);
    setphone(mobileNumber)
    setDialCode(dialCode)
  }

  //------------------------- COMPLETE PROFILE API -------------------------//
  function completeTeacherProfile(file: any) {
    let formdata = new FormData();
    formdata.append('userId', location?.state?.userId);
    formdata.append('dob', dob);
    formdata.append('school_college', school_college);
    formdata.append('class_url', class_url);
    formdata.append('phone', phone);
    formdata.append('name', location?.state?.name);
    formdata.append('dob', dob);
    formdata.append('address', address);
    formdata.append('teacher_exp', teacher_exp);
    formdata.append('highest_qualification', highest_qualification);
    formdata.append('timezone', timezone);
    formdata.append('currency', currency);
    formdata.append('address', address);
    formdata.append('dial_code', DialCode);
    formdata.append('document', document);
    formdata.append('info', info);
    formdata.append('abbrev', abbrev);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/teacher-completeprofile`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    setLoadingSpinner(
      <RotatingLines
        strokeColor="white"
        strokeWidth="5"
        animationDuration="0.75"
        width="25"
        visible={true}
      />)
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.push({
              pathname: '/teacher/info',
              state: { detail: location?.state?.userId, currency: currency, timezone: timezone }
            });
          }, 2000);
        }
        else {
          setLoadingSpinner('Next')
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
      });
  }

  return (
    <>
      <ToastContainer limit={1} />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent} ${st.marginTopp}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.sticky}`}>
              <Row className={`${cx.titleBar}`}>
                <Col>
                </Col>
                <Col>
                </Col>
                <Col className="text-end">
                </Col>
              </Row>
            </div>
            <img src={bg} className={`${cx.bgShape}`} />
            <Col className="text-center"><img src={logo} className={`${cx.logo}`} />
            </Col>
          </div>
          <div className={`${cx.contentBody} mt-3`}>
            <Col lg={4} className={'m-auto'}>
              <h5 className={`${st.themeColor}`}>Complete Profile</h5>
              <p></p>
            </Col>
            <Col lg={4} className={'m-auto'}>
              <Form onSubmit={handleSubmit(completeTeacherProfile)} className={`${st.formFull}`}>
                <Form.Group className={`${st.formField}`} controlId="">
                  <label htmlFor="">Name</label>
                  <Form.Control type="text"
                    value={location?.state?.name}
                    onChange={(e) => {
                      setName(e.target.value)
                    }} />
                </Form.Group>

                <div className={`${cx.profileEdit}`}>
                  <label htmlFor="">Mobile No</label>
                  <PhoneInput
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    onChange={phoneInput}
                    defaultErrorMessage="It doesn't works, why?"
                  />
                </div>

                <Form.Group className={`${st.formField}`} controlId="">
                  <label htmlFor="">Date of birth</label>
                  <Form.Control type="date" placeholder="DOB"
                    defaultValue={localData?.dob}
                    key={props?.dob}
                    max={maxDate[0]}
                    onChange={(e) => {
                      setDob(e.target.value)
                    }} />
                </Form.Group>

                <Row>
                  <Col className="col-6">
                    <div className={`${cx.profileEdit}`}>
                      <label htmlFor="">Timezone</label>
                      <TimezoneSelect
                        value={timezone}
                        // labelStyle="abbrev"
                        onChange={(e: any) => {
                          setTimezone(e.value)
                          setAbbrev(e?.abbrev)
                        }}
                        timezones={{
                          ...allTimezones,
                          'Asia/Kolkata': 'IST (Indian Standard Time)',
                          'Asia/Dubai': 'GST (Gulf Time)',
                          'America/Los_Angeles': 'PST/PDT(USA - Pacific Time)',
                          'America/Detroit': 'EST/EDT(USA - Eastern Time)',
                          'America/Chicago': 'CST/CDT (USA - Central Time)',
                          'America/Boise': 'MST/MDT(USA -  Mountain Time)',
                          'Asia/Kuala_Lumpur': 'SGT(Singapore Time)',
                          'Europe/London': "BST/GMT"
                        }} />
                    </div>
                  </Col>

                  <Col>
                    <Form.Group className={`${st.formField}`} controlId="">
                      <label htmlFor="">Currency</label>
                      <Form.Select aria-label=""
                        key={props?.class_id}
                        onChange={(e) => {
                          setCurrency(e.target.value)
                        }}>
                        <option value="">Select Currency</option>
                        <option value="INR-₹">INR- ₹</option>
                        <option value="USD-$">USD- $</option>
                        <option value="AUD-$">AUD- $</option>
                        <option value="CAD-$">CAD- $</option>
                        <option value="EUR-€">EUR- €</option>
                        <option value="NZD-$">NZD- $</option>
                        <option value="GBP-£">GBP- £</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className={`${st.formField}`} controlId="">
                  <label htmlFor="">Qualification</label>
                  <Form.Control aria-label="" key={props?.class_id} placeholder="Highest Qualification- University/College"
                    onChange={(e) => {
                      setHighest_qualification(e.target.value)
                    }} />
                </Form.Group>

                <Form.Group className={`${st.formField}`} controlId="">
                  <label htmlFor="">Teaching experience(in years)</label>
                  <Form.Control aria-label="" type="number" min={0} placeholder="Teaching experience(in years)"
                    onChange={(e) => {
                      setTeacher_exp(e.target.value)
                    }} />
                </Form.Group>

                <Form.Group className={`${st.formField}`} controlId="">
                  <label htmlFor="">Class URL</label>
                  <Form.Control type="text" placeholder="Class URL"
                    onChange={(e) => {
                      setClass_url(e.target.value)
                    }} />
                </Form.Group>

                <Form.Group className={`${st.formField}`} controlId="">
                  <label htmlFor="">Your Information</label>
                  <Form.Control as="textarea" placeholder="Your Information" rows={3} style={{ minHeight: '120px' }}
                    {...register("info", {
                      required: true,
                      minLength: {
                        value: 100,
                        message: "Minimum 100 characters required in information",
                      }
                    })}
                    onChange={(e) => {
                      setInfo(e.target.value)
                    }}
                  />
                  {errors?.info?.type === "minLength" && (
                    <p style={{ color: "red" }}>
                      Minimum 100 characters required in information
                    </p>
                  )}
                  {errors?.info?.type === "required" && (
                    <p style={{ color: "red" }}>This field is required</p>
                  )}
                </Form.Group>

                <Form.Group className={`${st.formField}`} controlId="">
                  <label htmlFor="">Address</label>
                  <Form.Control as="textarea" placeholder="Enter your Address" rows={3} style={{ minHeight: '120px' }}
                    onChange={(e) => {
                      setAddress(e.target.value)
                    }} />
                </Form.Group>

                <Form.Group className={`${st.formField}`} controlId="">
                  <label htmlFor="">ID proof</label>
                  <Form.Control aria-label="Upload valid ID proof" type="file" placeholder="Upload valid ID proof"
                    onChange={(e: any) => {
                      setDocument(e.target.files[0])
                    }} />
                </Form.Group>

                <Form.Group className={`${st.formField}`}>
                  <Col lg={12}>
                    <Button className={`btn ${st.actionBtn}`}
                      type="submit">
                      {loadingSpinner}
                    </Button>
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          </div>
        </div>
      </section>
    </>
  );
}