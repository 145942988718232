import React, { useState, useEffect, useRef } from "react";
import cx from "./ChatList.module.scss";
import { NavLink } from "react-router-dom";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { MdArrowBackIos } from "react-icons/md";
import { FaTelegramPlane } from "react-icons/fa";
import { GrAttachment } from "react-icons/gr";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios';
import userLogo from '../../images/userLogo.jpeg';
import ReactLoading from "react-loading";
import { useDispatch } from 'react-redux'
import { allIDs } from "../../redux-toolkit/reducer/chatCount";
import { Unsubscribe, addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import {
    query,
    orderBy,
    onSnapshot,
    limit,
} from "firebase/firestore";
import { db } from "../../firebase";
import useKeyPress from "../../Custom_Components/EnterButton/EnterButton";
import groupItems from "../../utils/groupbyObjectKey";
import Header from "../../components/Header/Header";

export default function Chats(): any {
    useEffect(() => {
        upcomingClasses();
    }, []);

    const chatContainerRef: any = useRef(null);
    const dispatch = useDispatch();
    let localData = JSON.parse(localStorage.getItem("userData")!);
    const enterPressed = useKeyPress("Enter");

    const [reciverId, setReciverId] = useState<any>(null);
    const [teacherName, setTeacherName] = useState<any>('');
    const [fcmToken, setFcmToken] = useState('');
    const [mobileView, setMobileView] = useState(false);
    const [loaderStatus, setloaderStatus] = useState(<div className="d-flex justify-content-center align-items-center mt-5"><ReactLoading type="spin" color="blue" width='40px' /></div>);
    const [messages, setMessages] = useState<any>([]);
    const [recivedMsgs, setRecivedMsgs] = useState<any>([]);
    const [text, setText] = useState('');
    const [filterItem, setFilterItem] = useState<any[]>([]);
    const [imageLink, setImageLink] = useState<any>();

    // -------------------------------------------- UPCOMING CLASS API --------------------------------------------//
    function upcomingClasses() {
        let formdata = new FormData();
        var config = {
            method: "get",
            url: `${process.env.REACT_APP_BASEURL}/${localData?.role_id == 6 ? 'upcomming_past_data' : 'teacher_upcomming_past_data'}`,
            headers: {
                Authorization: "Bearer" + localStorage.getItem("api_token"),
            },
            data: formdata,
        };
        axios(config)
            .then(function (response) {
                if (response.data.data) {
                    setloaderStatus(<div style={{ textAlign: 'center' }}>Chat will be available once student enrolls for paid sessions.</div>);
                }
                const chatNames = response?.data?.data?.filter((obj: any, index: any) =>
                    response.data.data.findIndex((event: any) => localData?.role_id == 6 ? event.teacher_id == obj.teacher_id : event.student_id == obj.student_id) == index
                );
                const filterItemArr: any = [];
                let allChatID: any = []
                chatNames.map((filterItem: any) => {
                    allChatID?.push(localData?.role_id == 6 ? `${filterItem?.teacher_id}_${localData?.id}` : `${filterItem?.student_id}_${localData?.id}`)
                    setReciverId(localData?.role_id == 6 ? filterItem?.teacher_id : filterItem?.student_id)
                    dispatch(allIDs(localData?.role_id == 6 ? filterItem?.teacher_id : filterItem?.student_id))
                    filterItemArr?.push(
                        {
                            teacher: filterItem?.name,
                            id: localData?.id == filterItem?.teacher_id ? filterItem?.student_id : filterItem?.teacher_id,
                            class: filterItem?.class_name,
                            subject: filterItem?.subject_name,
                            data: filterItem
                        }
                    );
                })
                setFilterItem(filterItemArr)
            })
            .catch(function (error) {
                console.log(error, 'error');
            });
    }

    // UNREAD MESSAGE FUNCTIONALITY
    let trueValues: any[] = [];
    let documentId: any = [];
    let unReadId: any = []
    if (trueValues?.length === 0) {
        recivedMsgs?.forEach((item: any) => {
            if (item?.seen == false) {
                documentId?.push(item?.id)
                trueValues?.push({ seen: item?.seen, userId: item?.uid });
                unReadId?.push(item?.uid)
            }
        });
    }

    const chatId = `${localData?.id}_${reciverId}`;
    const reciversChatId = `${reciverId}_${localData?.id}`

    let allRecivedsChatId: any = []
    allRecivedsChatId?.push(`${reciverId}_${localData?.id}`)

    const sendMessage = async () => {
        setText("");
        const messageData = {
            text: text,
            name: localData?.name,
            createdAt: Date.now(),
            uid: localData?.id,
            rid: reciverId,
            firebaseId: `${localData?.id}-${reciverId}`,
            attachment: text === "" ? imageLink : "",
            seen: false,
        };

        try {
            const chatDocRef = doc(db, "messages", 'solo-message');
            const subcollectionRef = collection(chatDocRef, chatId);
            await addDoc(subcollectionRef, { ...messageData, messageID: chatId });
        } catch (error) {
            console.error("Error adding message: ", error);
        }
    };

    //-------------------------------------- FOR SENDER -------------------------------------- 
    useEffect(() => {
        const q = query(
            collection(db, 'messages', 'solo-message', chatId),
            orderBy('createdAt', 'desc'),
            limit(50)
        );

        const unsubscribe: Unsubscribe = onSnapshot(q, (QuerySnapshot) => {
            const fetchedMessages: any = [];
            QuerySnapshot.forEach((doc) => {
                fetchedMessages.push({ ...doc.data(), id: doc.id });
            });
            const sortedMessages = fetchedMessages.sort(
                (a: any, b: any) => a.createdAt - b.createdAt
            );
            setMessages(sortedMessages);
        });

        return unsubscribe;
    }, [chatId]);

    // -------------------------------------- FOR RECIVER ------------------------------------
    useEffect(() => {
        if (reciverId) {
            const q = query(
                collection(db, 'messages', 'solo-message', reciversChatId),
                orderBy('createdAt', 'desc'),
                limit(50)
            );
            const unsubscribe: Unsubscribe = onSnapshot(q, (QuerySnapshot) => {
                const fetchedMessages: any = [];
                QuerySnapshot.forEach((doc) => {
                    fetchedMessages.push({ ...doc.data(), id: doc?.id });
                });
                const sortedMessages = fetchedMessages.sort(
                    (a: any, b: any) => a.createdAt - b.createdAt
                );
                setRecivedMsgs(sortedMessages);
            });
            return unsubscribe;
        }

    }, [reciversChatId]);

    // Combine both arrays into a single array for sorting
    const allMessages: any = [...recivedMsgs, ...messages];

    // Sort the combined array by the 'createdAt' timestamp
    allMessages.sort((a: any, b: any) => a.createdAt - b.createdAt);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [allMessages]);

    // -----------------------------CHAT IMAGE/ATTACHMENT API-----------------------------//
    function uploadHomework(files: any) {
        let formdata = new FormData();
        formdata.append("image", files.target.files[0]);
        formdata.append("student_id", localData?.id);
        formdata.append("teacher_id", reciverId);

        var config = {
            method: "post",
            url: `${process.env.REACT_APP_BASEURL}/image_store`,
            headers: {
                Authorization: "Bearer" + localStorage.getItem("api_token"),
            },
            data: formdata,
        };
        axios(config)
            .then(function (response) {
                setImageLink(response?.data?.data?.image)
            })
            .catch(function (error) {
                console.log(error, "attachmentError")
            });
    }

    // -------------------------------------------- PUSH NOTIFICATION API ------------------------------------//
    function pushNotification() {

        let data: any = JSON.stringify({
            "to": fcmToken,
            "notification": {
                "title": teacherName,
                "body": text,
                "click_action": "https://learning.guruathome.com/teacher/home"
            }
        });

        var config = {
            method: "POST",
            url: `https://fcm.googleapis.com/fcm/send`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer AAAAHospWg8:APA91bEO2t5wUNyU8kwdzd8z_UoimyZl638DALOgOnmvGzJhNVGrru-NToZX_tKPbLpqoo9TyBIrUqQS8gwjO1Mrr2yC1-u7pTeGCId4EqjY-zdoblGVWQseNa2zrPFWehILlK9UTjy0",
            },
            data: data,
        };
        axios(config)
    }
    
    useEffect(() => {
        sendMessage();
    }, [imageLink])

    // UPDATE DOCS FOR READ THE MESSAGES
    const updateAllDocuments = async () => {
        try {
            for (const id of documentId) {
                const docRef = doc(db, 'messages', 'solo-message', reciversChatId, id);
                await updateDoc(docRef, {
                    seen: true,
                });
            }
        } catch (e) {
            console.error('Error updating documents: ', e);
        }
    };

    useEffect(() => {
        if (mobileView == true) {
            setInterval(() => {
                updateAllDocuments();
            }, 1000)
        }
    }, [recivedMsgs])

    useEffect(() => {
        if (enterPressed == true && text != "") {
            sendMessage();
            pushNotification();
        }
    }, [enterPressed])

    function getFileExtension(url: any) {
        const path = new URL(url).pathname;
        const lastDotIndex = path.lastIndexOf(".");
        if (lastDotIndex === -1) {
            return "";
        }
        const extension = path.substring(lastDotIndex + 1);
        return extension;
    }

    return (
        <>
            <Header />
            <section className={`${cx.chatListSection}`}>
                <main>
                    {filterItem?.length > 0 ? <div className={`${cx.chat_TabBox}`}>
                        <Tab.Container id="left-tabs-example">
                            <div className={`container`}>
                                <Row className={`${cx.mobileRow}`}>

                                    <Col lg={4} className={`${cx.col4L}`}>
                                        {mobileView == false ? <Col className={`${cx.chatting_TitleMain}`}>
                                            <div className={`${cx.chatting_arrowName}`}>Chat</div>
                                        </Col> : ''}
                                        <div className={mobileView === true ? `${cx.scrolling_chat} ${cx.chatting_Scrolling_MobileHide}` : `${cx.scrolling_chat}`}>
                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item>
                                                    {filterItem?.map((item: any, index: number) => {
                                                        return (
                                                            <Nav.Link eventKey={index}
                                                                onClick={() => {
                                                                    updateAllDocuments();
                                                                    setReciverId(item?.id);
                                                                    setMobileView(true);
                                                                    setTeacherName(item?.teacher);
                                                                    setFcmToken(item?.data?.fcmtoken);
                                                                }}>
                                                                <div className={`${cx.chat_Avatar}`}>
                                                                    <div key={item.tId} className={`${cx.chatAvatar_Main}`}>
                                                                        <img key={item.tId} src={item.data.image || userLogo} alt={`Image ${item.tId}`} />
                                                                    </div>
                                                                    <div className={`${cx.users_list_body}`}>
                                                                        <div className={`${cx.chat_msg}`}>
                                                                            <h6>{item?.teacher ?? item?.data?.student_name}</h6>
                                                                        </div>
                                                                        <div className={`${cx.chat_time}`}>
                                                                            {Object.entries(groupItems(recivedMsgs, 'uid'))
                                                                                ?.filter(([keyId]) => item?.id == keyId)[0]?.[1]
                                                                                ?.filter((item: any) => item?.seen == false)
                                                                                ?.length > 0 && mobileView == false && (
                                                                                    <span className={`${cx.count}`}>
                                                                                        {Object.entries(groupItems(recivedMsgs, 'uid'))
                                                                                            ?.filter(([keyId]) => item?.id == keyId)[0]?.[1]
                                                                                            ?.filter((item: any) => item?.seen == false)
                                                                                            ?.length}
                                                                                    </span>
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Nav.Link>
                                                        )
                                                    })
                                                    }
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                    </Col>
                                    {mobileView == true ? <Col lg={8} className={`${cx.col8L}`}>
                                        <Tab.Content>
                                            {filterItem.map((item: any, index: any) => {
                                                return (
                                                    <Tab.Pane eventKey={index}>
                                                        <div className={`${cx.chatting_contain}`}>
                                                            <div className={`${cx.chatting_backArrowMain}`}>
                                                                <Col className="col-2">
                                                                    <div className={`${cx.chatting_arrowIos}`}>
                                                                        <NavLink to="#" className={`${cx.back}`}>
                                                                            <MdArrowBackIos
                                                                                onClick={() => {
                                                                                    updateAllDocuments();
                                                                                    setMobileView(false);
                                                                                }} />
                                                                        </NavLink>
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-8">
                                                                    <div className={`${cx.chatting_arrowName}`}>{item?.teacher}</div>
                                                                    <div className={`${cx.chat_time}`}>
                                                                        {Object.entries(groupItems(recivedMsgs, 'uid'))
                                                                            ?.filter(([keyId]) => item?.id == keyId)[0]?.[1]
                                                                            ?.filter((item: any) => item?.seen == false)
                                                                            ?.length > 0 && (
                                                                                <span className={`${cx.count}`}>
                                                                                    {Object.entries(groupItems(recivedMsgs, 'uid'))
                                                                                        ?.filter(([keyId]) => item?.id == keyId)[0]?.[1]
                                                                                        ?.filter((item: any) => item?.seen == false)
                                                                                        ?.length}
                                                                                </span>
                                                                            )}
                                                                    </div>
                                                                </Col>
                                                            </div>
                                                            <div className={`${cx.chat_person_head}`}>
                                                                <Col lg={12}>
                                                                    <div className={`${cx.chat_showperson}`}>
                                                                        <img src={item?.data?.image || userLogo} alt="image" />
                                                                        <div className={`${cx.chat_showContent}`}>
                                                                            <h5>{teacherName}</h5>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </div>
                                                            <div className={`${cx.chatting_messagesContainer}`} ref={chatContainerRef}>
                                                                {allMessages.map((item: any) => {
                                                                    return (
                                                                        <div className={`message`}>
                                                                            <div className={localData?.id == item?.rid ? `${cx.left}` : `${cx.right}`}>
                                                                                <div className={`${cx.bgChat}`}>
                                                                                    {item?.text ? <p>{item?.text}</p> : ''}
                                                                                    <a target="_blank" href={item?.attachment}>
                                                                                        {(item?.attachment) ?
                                                                                            <p><img style={{ width: '150px', height: '150px' }} src={getFileExtension(item?.attachment) == "docx" ?
                                                                                                "https://www.iconpacks.net/icons/2/free-attachment-icon-1483-thumb.png" : getFileExtension(item?.attachment) == "pdf" ?
                                                                                                    "https://png.pngtree.com/png-clipart/20220612/original/pngtree-pdf-file-icon-png-png-image_7965915.png" :
                                                                                                    item?.attachment}
                                                                                                alt="https://www.iconpacks.net/icons/2/free-attachment-icon-1483-thumb.png" /></p> :
                                                                                            ''}
                                                                                    </a>
                                                                                    <time>
                                                                                        {
                                                                                            new Date(item?.createdAt).toLocaleString("en-us", {
                                                                                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                                                                                hour: "2-digit",
                                                                                                minute: "2-digit",
                                                                                                day: '2-digit',
                                                                                                month: '2-digit'
                                                                                            })
                                                                                        }
                                                                                    </time>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                        {/* <div style={{ height: '100px' }}></div> */}
                                                        <div className={`${cx.chatForm}`}>
                                                            <div className={`${cx.chatType}`}>
                                                                <input type="text" className="form-control" placeholder="Type a message..." value={text}
                                                                    onChange={e => setText(e.target.value)} />
                                                                <div className={`${cx.actionIcons}`}>
                                                                </div>
                                                            </div>
                                                            <button className="btn" type="submit">
                                                                <GrAttachment />
                                                                <input type="file"
                                                                    onChange={(file: any) => {
                                                                        uploadHomework(file);
                                                                    }}
                                                                />
                                                            </button>
                                                            {text !== "" ? <button type="submit" className="btn" onClick={() => {
                                                                if (text != "") {
                                                                    sendMessage();
                                                                    pushNotification();
                                                                }
                                                            }}>
                                                                <FaTelegramPlane />
                                                            </button> :
                                                                <button type="submit" disabled className="btn" onClick={() => {
                                                                    if (text != "") {
                                                                        sendMessage();
                                                                        pushNotification();
                                                                    }
                                                                }}>
                                                                    <FaTelegramPlane />
                                                                </button>}
                                                        </div>
                                                    </Tab.Pane>
                                                )
                                            })}
                                        </Tab.Content>
                                    </Col> : ''}
                                </Row>
                            </div>
                        </Tab.Container>
                    </div> : loaderStatus}
                </main>
            </section >
        </>
    );
}