import React, { useState, useEffect } from "react";
import axios from 'axios';
import st from "../../../style.module.scss";
import cx from "./Referalsoffers.module.scss";
import { NavLink } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";
import { FaLink } from "react-icons/fa";
import { Col, Row, Nav, Tab } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import offer from "../../../images/offer-img.svg";
import user from "../../../images/userLogo.jpeg";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Student/Footer/Footer";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShareOnSocial from "react-share-on-social";

export default function Referalsoffers() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    referallandoffers();
  }, [])

  const [data, setData] = useState<any>([]);
  const [refer, setrefer] = useState<any>([]);
  const [refer_code, setRefer_code] = useState('');
  const [refer_link, setRefer_link] = useState('');
  const [Copy, setCopy] = useState("Copy");

  const [loaderStatus, setloaderStatus] = useState(
    <ReactLoading type="cylon" color="blue" />
  );

  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  // function removeTags(str: any) {
  //   if ((str === null) || (str === ' ') || (str === undefined))
  //     return false;
  //   else
  //     str = str.toString();
  //   str = str.replace(/&nbsp;/g, '')
  //   return str.replace(/(<([^>]+)>)/ig, '')
  // }

  // ---------------------------MY Referall and Offers API-------------------------- //
  function referallandoffers() {

    let formdata = new FormData();
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/referalsAndoffer`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.data) {
          setloaderStatus(<div></div>);
        }
        setData(response?.data?.data)
        setrefer(response?.data?.refers)
        setRefer_code(response?.data?.refer_code)
        setRefer_link(response?.data?.refer_link)
      })
      .catch(function (error) {
        console.log(error)
        notify("Something went wrong, Please refresh the page or try again later");
      });
  }

  return (
    <>
      <Header />
      <ToastContainer limit={1} />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.titleBar} ${st.fixHeader}`}>
              <Row className={`align-items-center ${st.width100}`}>
                <Col className={`col-2`}>
                  <NavLink to="/student/profile" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col className={`col-8 ${cx.headTitle}`}>
                  <h6>Referals and Offers</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
          </div>
          <div className={`${cx.spaceBox}`}></div>

          <Tab.Container id="left-tabs-example" defaultActiveKey="tabClass1">
            <div className={`container ${cx.menuList}`}>
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link eventKey="tabClass1">Referrals</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tabClass2"
                    onClick={referallandoffers}>Offers</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className={`container ${cx.contentBody}`}>

              <Tab.Content>
                {/* REFERALL */}

                <Tab.Pane eventKey="tabClass1">
                  <div className={`${cx.classCard}`}>
                    <img alt="offer" src={offer} className={`${cx.arrowImg}`} />
                    <h5>Refer your friends and win Free Sessions</h5>
                    <p>
                      You will get {refer?.free_class} sessions when your friend purchases 4 or more sessions.
                      Your friend will get Flat {refer?.free_percantage}% discount on first purchase
                    </p>
                  </div>

                  <Row className={`text-center mb-4`}>
                    <div className={`${cx.referBtn}`}>
                      <input className={`${cx.referInput}`} type="Submit" value={refer_code} />
                      <input className={`${cx.referCopy}`} type="Submit" value={Copy}
                        onClick={() => {
                          navigator.clipboard.writeText(refer_code)
                          setCopy("Copied");
                        }} />
                    </div>
                  </Row>

                  <div className={`mt-2 ${cx.referNow}`}>
                    <input className={`${cx.referLink}`} type="text" value={refer_link} />
                    <FaLink />
                    <ShareOnSocial
                      linkFavicon={user}
                      link={refer_link}
                      textToShare="Click to get some education">
                      <input className={`${cx.referCopy}`} type="Submit" value="Refer Now" />
                    </ShareOnSocial>
                  </div>

                  {/* REFERALS */}
                  <Row>
                    {data?.refers?.length == 0 && (
                      <div className="d-flex justify-content-center">
                        {loaderStatus}
                      </div>
                    )}
                    {data?.refers?.map((item: any, index: any) => {
                      let parts = item?.created_at.slice(0, 10).split("-");
                      let formattedDate = `${parts[1]}/${parts[2]}/${parts[0]}`;
                      return (
                        <Col lg={4}>
                          {item?.name && <div className={` mt-3 ${cx.referalsHistory}`}>
                            <div className={`${cx.referalsBox}`}>
                              <h6>{item?.name}</h6>
                              <p><span> Date - {formattedDate}</span></p>
                            </div>
                            <p>Free Reward - {item?.free_class} Session(s)</p>
                          </div>}
                        </Col>
                      );
                    })}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="tabClass2">

                  {/* OFFERS */}
                  <Row>
                    {data?.offers?.length == 0 && (
                      <div className="d-flex justify-content-center">
                        {loaderStatus}
                      </div>
                    )}
                    {data?.offers?.map((item: any, index: any) => {
                      let parts = item?.end_valid_date.slice(0, 10).split("-");
                      let formattedDate = `${parts[1]}/${parts[2]}/${parts[0]}`;
                      return (
                        <Col lg={4}>
                          <div className={` mb-3 ${cx.classCard} ${cx.offerBox}`}>
                            <div className={`${cx.offerDemo}`}>
                              <h6>{item?.coupon_code}</h6>
                              <p><span>Vaild - {formattedDate}</span></p>
                            </div>
                            <div className={`${cx.offerDemo}`}>
                              <h6>{item?.title}</h6>
                            </div>
                            {/* <p style={{ textAlign: "justify", lineHeight: "20px" }} className={`${cx.offerText}`}>{removeTags(item?.description)}</p>112233 */}
                            <div
                              className={`${st.fontColor} text-left mt-2 mb-3`}
                              style={{ fontSize: "15px" }}
                              dangerouslySetInnerHTML={{ __html: item?.description }}
                            />
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
          <div className={`pt-3 ${st.spaceBox}`}></div>
        </div>
      </section >
      <Footer />
    </>
  );
}