import React, { useEffect, useState } from "react";
import st from "../../../style.module.scss";
import axios from 'axios';
import cx from "./Editprofile.module.scss";
import { NavLink, useHistory } from "react-router-dom";
import { Col, Row, Form, Button } from "react-bootstrap";
import { MdArrowBackIos } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";
import bg from "../../../images/bg-img-new.svg";
import edit from "../../../images/edit-img.svg";
import user from "../../../images/userLogo.jpeg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TimezoneSelect, { allTimezones } from 'react-timezone-select';
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput, { CountryData } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { RotatingLines } from "react-loader-spinner";
import ProfileAvatar from "../../../Custom_Components/ProfileAvatar/ProfileAvatar";

export default function Teachereditprofile(props: any) {
  let date = new Date().toISOString();
  let maxDate = date.split("T");
  let localData = JSON.parse(localStorage.getItem("userData")!);

  //---react-toastify---//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const navigate = useHistory();
  const [email, setEmail] = useState(localData?.email);
  const [name, setName] = useState(localData?.name);
  const [phone, setphone] = useState(localData?.phone);
  const [dob, setDob] = useState(localData?.dob);
  const [school_college, setSchool_college] = useState(localData?.school_college);
  const [address, setAddress] = useState(localData?.address);
  const [teacher_exp, setTeacher_exp] = useState(localData?.teacher_exp);
  const [highest_qualification, setHighest_qualification] = useState(localData?.highest_qualification);
  const [timezone, setTimezone] = useState(localData?.timezone);
  const [currency, setCurrency] = useState(localData?.currency);
  const [class_url, setClass_url] = useState(localData?.class_url);
  const [DialCode, setDialCode] = useState(localData?.dial_code || localData?.country_code);
  const [image, setImage] = useState<any>(localData?.image);
  const [pic, setPic] = useState<any>("");
  const [abbrev, setAbbrev] = useState('');
  const [loadingSpinner, setLoadingSpinner] = useState<any>('Submit');

  function phoneInput(value: any, data: CountryData) {
    const dialCode = `+${data.dialCode}`;
    const mobileNumber = value?.substring(data?.dialCode?.length);
    setphone(mobileNumber)
    setDialCode(dialCode)
  }

  //------------------------------------ EDIT TEACHERS API ------------------------------//
  function UpdateProfile() {

    let formdata = new FormData();
    formdata.append('userId', localData?.id);
    formdata.append('teaching_experience', localData?.teacher_exp);
    formdata.append('email', email);
    formdata.append('name', name);
    formdata.append('dob', dob);
    formdata.append('school_college', school_college);
    formdata.append('address', address);
    formdata.append('abbrev', abbrev);
    formdata.append('teacher_exp', teacher_exp);
    formdata.append('highest_qualification', highest_qualification);
    formdata.append('timezone', timezone);
    formdata.append('currency', currency);
    formdata.append('class_url', class_url);
    formdata.append('image', pic == "" ? image : pic[0]);
    formdata.append("phone", phone !== "" ? phone : localData?.phone);
    formdata.append("dial_code", DialCode !== "" ? DialCode : localData?.dialCode);

    let config = {
      method: "POST",
      url: `${process.env.REACT_APP_BASEURL}/teacher-updateProfile`,
      headers: {
        "Authorization": "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    setLoadingSpinner(
      <RotatingLines
        strokeColor="white"
        strokeWidth="5"
        animationDuration="0.75"
        width="25"
        visible={true}
      />)
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          // localStorage.setItem("api_token", response.data.data.api_token);
          localStorage.setItem("userData", JSON.stringify(response.data.data))
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.push({
              pathname: '/teacher/profile',
              state: { detail: response.data.id }
            });
          }, 2000);
        }
        else {
          setLoadingSpinner('Submit');
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
      });
  }

  return (
    <>
      <ToastContainer limit={1} />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent} ${st.marginTopp}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.sticky}`}>
              <Row className={`${cx.titleBar}`}>
                <Col className={`col-2`}>
                  <NavLink to="/teacher/profile" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col className={`col-8 ${cx.aboutUs}`}>
                  <h6>Edit Profile</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
            <img src={bg} className={`${cx.bgShape}`} />
          </div>
          <div className={`${cx.contentBody} container mt-3`}>

            <Form className={`${cx.paddingLeft} ${st.formFull}`}>
              <Row>
                <Col className={`col-12 ${cx.profileText}`}>
                  <div className={`${cx.userView}`}>
                    {/* <img src={image == "" ? user : image} className={`${cx.userImg}`} /> */}
                    {image == "" || image == null ? <ProfileAvatar name={localData?.name} /> : <img alt="profile" src={image} className={`${cx.userImg}`} />}

                    <i className={`${cx.editIcon}`}><img src={edit} className={`${cx.editImg}`} />
                      <input type="file"
                        onChange={(e: any) => {
                          // console.log(e.target.files, "eforpic")
                          setPic(e.target.files)
                          setImage(URL.createObjectURL(e.target.files[0]))
                        }} /></i>
                  </div>
                </Col>

                <Col className="col-lg-6 col-12">
                  <div className={`${cx.profileEdit}`}>
                    <label htmlFor="">Name</label>
                    <input type="text"
                      defaultValue={localData.name}
                      key={props?.name}
                      onChange={(e) => {
                        setName(e.target.value)
                      }}
                    />
                  </div>
                </Col>

                <Col className="col-lg-6 col-12">
                  <div className={`${cx.profileEdit}`}>
                    <label htmlFor="">Email</label>
                    <input type="text"
                      disabled
                      value={localData.email}
                      key={props?.email}
                      onChange={(e) => {
                        setEmail(e.target.value)
                      }}
                    />
                  </div>
                </Col>

                <Col className="col-lg-6 col-12">
                  <div className={`${cx.profileEdit}`}>
                    <label htmlFor="">Phone Number</label>
                    <PhoneInput
                      inputProps={{
                        name: "",
                        required: true,
                        autoFocus: true,
                      }}
                      country={DialCode}
                      value={`${DialCode}${phone}`}
                      onChange={phoneInput}
                    />
                  </div>
                </Col>

                <Col className="col-lg-6 col-12">
                  <div className={`${cx.profileEdit}`}>
                    <label htmlFor="">Date of Birth</label>
                    <input type="date"
                      disabled
                      max={maxDate[0]}
                      value={localData?.dob.slice(0, 10)}
                      key={props?.dob}
                      onChange={(e) => {
                        setDob(e.target.value)
                      }} />
                  </div>
                </Col>

                <Col className="col-lg-6 col-12">
                  <div className={`${cx.profileEdit}`}>
                    <label htmlFor="">Timezone</label>
                    <TimezoneSelect
                      value={timezone}
                      // labelStyle="abbrev"
                      onChange={(e: any) => {
                        setTimezone(e?.value)
                        setAbbrev(e?.abbrev)
                      }}
                      timezones={{
                        ...allTimezones,
                        'Asia/Kolkata': 'IST (Indian Standard Time)',
                        'Asia/Dubai': 'GST (Gulf Time)',
                        'America/Los_Angeles': 'PST/PDT(USA - Pacific Time)',
                        'America/Detroit': 'EST/EDT(USA - Eastern Time)',
                        'America/Chicago': 'CST/CDT (USA - Central Time)',
                        'America/Boise': 'MST/MDT(USA -  Mountain Time)',
                        'Asia/Kuala_Lumpur': 'SGT(Singapore Time)',
                        'Europe/London': "BST/GMT"
                      }} />
                  </div>
                </Col>

                <Col className="col-lg-6 col-12">
                  <div className={`${cx.profileEdit}`}>
                    <label htmlFor="">Currency</label>
                    <Form.Select aria-label="Default select example"
                      disabled
                      defaultValue={localData.currency}
                      key={props?.currency}
                      onChange={(e) => {
                        setCurrency(e.target.value)
                      }}>
                      <option value="">Select Currency</option>
                      <option value="INR-₹">INR- ₹</option>
                      <option value="USD-$">USD- $</option>
                      <option value="AUD-$">AUD- $</option>
                      <option value="CAD-$">CAD- $</option>
                      <option value="EUR-€">EUR- €</option>
                      <option value="NZD-$">NZD- $</option>
                      <option value="GBP-£">GBP- £</option>
                    </Form.Select>
                  </div>
                </Col>


                <Col className="col-12">
                  <div className={`${cx.profileEdit}`}>
                    <label htmlFor="">Your Information</label>
                    <textarea name="" placeholder="University/College"
                      defaultValue={localData.school_college}
                      key={props?.school_college}
                      onChange={(e) => {
                        setSchool_college(e.target.value)
                      }}>
                    </textarea>
                  </div>
                </Col>

                <Col className="col-lg-6 col-12">
                  <div className={`${cx.profileEdit}`}>
                    <label htmlFor="">Highest Qualification</label>
                    <input type="text" placeholder="Highest Qualification"
                      defaultValue={localData.highest_qualification}
                      key={props?.highest_qualification}
                      onChange={(e) => {
                        setHighest_qualification(e.target.value)
                      }} />
                  </div>
                </Col>

                <Col className="col-lg-6 col-12">
                  <div className={`${cx.profileEdit}`}>
                    <label htmlFor="">Teaching experience(in years)</label>
                    <input placeholder="Teaching experience(in years)"
                      defaultValue={localData.teacher_exp}
                      type="number"
                      min={0}
                      key={props?.teacher_exp}
                      onChange={(e) => {
                        setTeacher_exp(e.target.value)
                      }} />
                  </div>
                </Col>

                <Col className="col-12">
                  <div className={`${cx.profileEdit}`}>
                    <label htmlFor="">Class URL</label>
                    <input type="text" placeholder="Class URL"
                      defaultValue={localData.class_url}
                      key={props?.class_url}
                      onChange={(e) => {
                        setClass_url(e.target.value)
                      }} />
                  </div>
                </Col>

                <Col className="col-12">
                  <div className={`${cx.profileEdit}`}>
                    <label htmlFor="">Address </label>
                    <textarea name="" placeholder="Address"
                      defaultValue={localData.address}
                      key={props?.address}
                      onChange={(e) => {
                        setAddress(e.target.value)
                      }}>
                    </textarea>
                  </div>
                </Col>

                <Col className="col-12">
                  <Button className={`btn ${cx.SubmitBtn}`}
                    onClick={UpdateProfile}>
                    {loadingSpinner}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </section>
    </>
  );
}