import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import m from "./Modal.module.scss";
import { NavLink } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const UpcomingReschedule = ({ show, handleClose, props, type }: any) => {
    let localData = JSON.parse(localStorage.getItem("userData")!);
    const notify = (message: String) =>
        toast(message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        });

    // -----------------------------RESCHEDULE API DEMO (NOTIFICATIONS) -----------------------------//
    function rescheduleUpcoming() {
        let formdata = new FormData();
        formdata.append("user_id", props?.student_id);
        formdata.append(`${type == "enroll" ? "student_enroll_schedule_id" : "demo_id"}`, type == "enroll" ? props?.id : props?.t_id);
        formdata.append("teacher_id", localData?.id);
        formdata.append("utcdate", props?.utc_start_time || props?.utcdate);
        formdata.append("type", type);

        var config = {
            method: "post",
            url: `${process.env.REACT_APP_BASEURL}/teacher_class_reschedule`,
            headers: {
                Authorization: "Bearer" + localStorage.getItem("api_token"),
            },
            data: formdata,
        };
        axios(config)
            .then(function (response) {
                notify('Reschedule request sent');
                handleClose();
            })
            .catch(function (error) {
                notify('Something went wrong. Try again later');
                console.log(error)
            });
    }
    return (
        <>
            <ToastContainer limit={1} />
            <Modal show={show} onHide={handleClose} centered className={`${m.reschedule_popUp}`}>
                <Modal.Header >
                    <Modal.Title>Do you want to Reschedule the class?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <NavLink to="#" className={`btn border-end ${m.actionBtn}`} onClick={handleClose}>
                        NO
                    </NavLink>
                    <Button className={`btn ${m.actionBtn}`} onClick={() => {
                        rescheduleUpcoming();
                    }}>
                        YES
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default UpcomingReschedule