import React, { useEffect, useState } from "react";
import st from "../../../style.module.scss";
import cx from "./Viewmore.module.scss";
import axios from "axios";
import { Col, Row, Form } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../../../components/Teacher/Footer/Footer";
import ReactLoading from "react-loading";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function TeacherViewmore() {
  let localData = JSON.parse(localStorage.getItem("userData")!);
  const selectedDay = (val: any) => {
  };

  useEffect(() => {
    viewMore();
  }, [])

  const [loaderStatus, setloaderStatus] = useState(
    <ReactLoading type="cylon" color="blue" />
  );

  const location: any = useLocation();
  const [data, setData] = useState([]);
  const [class_end_date, setClass_end_date] = useState("");

  //---react-toastify---//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);
  const [type, setType] = useState<any>(1);

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  // ---------------------------VIWE MORE API------------------------------ //
  function viewMore() {

    let formdata = new FormData();
    formdata.append('class_end_date', location.state.state.details);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/view_more_class`,
      headers: {
        "Authorization": "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.data) {
          setloaderStatus(<div>Classes not Available ...</div>);
        }
        if (response.data.statusCode == 200) {
          setData(response.data.data)
        }
        else {
          // setTimenotify(response.data.message);
          // setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        notify("Something went wrong");
      });
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.titleBar} ${st.fixHeader}`}>
              <Row className={`align-items-center ${st.width100}`}>
                <Col className={`col-2`}>
                  <NavLink to="/teacher/classes" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col className={`col-8 ${cx.headTitle}`}>
                  <h6>View More</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
          </div>
          <div className={`${cx.spaceBox}`}></div>

          <div className={`${cx.contentBody}`}>
            <Row>
              {data?.length == 0 && (
                <div className="d-flex justify-content-center">
                  {loaderStatus}
                </div>
              )}
              {data?.map((item: any, index: any) => {
                const parts = item?.date?.split("-");
                const formattedDate = `${parts?.[1]}/${parts?.[2]}/${parts?.[0]}`;
                return (
                  <Col lg={4}>
                    <div className={`${cx.classCard}`}>
                      <ul>
                        <li>
                          <span><h5>{item?.student_name}</h5></span>
                          <span className={`${cx.themeColor}`}>Date - {formattedDate}</span>
                        </li>
                        <li>
                          <span>{item?.subject_name ? "Subject" : "Course"} - {item?.subject_name || item?.course_name}</span>
                          <span>{item?.class_name ? "Class" : "Category"} - {item?.class_name || item?.category_name}</span>
                        </li>
                        <li>
                          <span>Hours - {item?.total_hours} Hours</span>
                          <span>Payment - {localData?.currency.slice(4)}{item?.teacher_payment}</span>
                        </li>
                      </ul>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        {/* <Rightbody /> */}
      </section>
      <Footer />
    </>
  );
}