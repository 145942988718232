import React, { useState, useEffect } from "react";
import st from "../../../style.module.scss";
// import cx from "./Otp.module.scss";
import axios from 'axios';
import cx from "./Aboutus.module.scss";
import { NavLink } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { MdArrowBackIos } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";
import bg from "../../../images/bg-img-new.svg";
import Footer from "../../../components/Teacher/Footer/Footer";
import Header from "../../../components/Header/Header";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function Teacheraboutus() {

  const [loaderStatus, setloaderStatus] = useState(
    <ReactLoading type="cylon" color="blue" />
  );
  const [data, setData] = useState<any>({});

  useEffect(() => {
    aboutUs();
  }, [])

  // function removeTags(str: any) {
  //   if ((str === null) || (str === '') || (str === undefined))
  //     return false;
  //   else
  //     str = str.toString();
  //   return str.replace(/(<([^>]+)>)/ig, '');
  // }
  //---react-toastify-----//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);
  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  // -------------------------------------------- ABOUT US API ------------------------------------------------//
  function aboutUs() {

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/aboutUsPrivacy_PolicyTerms_Condition`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
    };
    axios(config)
      .then(function (response) {
        if (response.data.data) {
          setloaderStatus(<div>Something went wrong ...</div>);
        }
        setData(response.data.data)
      })
      .catch(function (error) {
        notify("Something went wrong");
      });
  }

  return (
    <>
      <Header />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent} ${st.marginTopp}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.sticky}`}>
              <Row className={`${cx.titleBar}`}>
                <Col className={`col-2`}>
                  <NavLink to="/teacher/profile" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col className={`col-8 ${cx.aboutUs}`}>
                  <h6>About us</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
            <img src={bg} className={`${cx.bgShape}`} />
          </div>
          <div className={`container ${cx.contentBody} mt-3`}>
            <Form>
              <Form.Group className={`${st.formField}`} controlId="">
                <h5 className="mb-3">About Us</h5>
                {(data?.data?.about_us?.length === 0) && (
                  <div className="d-flex justify-content-center">
                    {loaderStatus}
                  </div>
                )}
                {/* <p className={`${st.fontColor} text-left mt-2 mb-3`} style={{ fontSize: '15px' }}>
                  {removeTags(data?.about_us)}</p> */}
                <div
                  className={`${st.fontColor} text-left mt-2 mb-3`}
                  style={{ fontSize: "15px" }}
                  dangerouslySetInnerHTML={{ __html: data?.about_us }}
                />
              </Form.Group>
            </Form>
          </div>
        </div>
        {/* <Rightbody /> */}
      </section>
      <Footer />
    </>
  );
}