import React from "react";
import st from "../../../style.module.scss";
import cx from "./Privacypolicy.module.scss";
import { Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export default function StaticPrivacypolicy() {
  let data = `
    <p>At <b>Guru At Home</b>, we operate <a href="https://learning.guruathome.com/" target="_blank" rel="noopener noreferrer">https://learning.guruathome.com/</a>. This Privacy Policy explains how we collect, use, and protect the personal information of our users, including students, tutors, and admins.</p>

    <h6>1. Information We Collect</h6>
    <ul>
      <li><b>Personal Information:</b> Name, email address, phone number, and other contact details.</li>
      <li><b>Account Information:</b> Username, password, and profile details.</li>
      <li><b>Educational Information:</b> Course enrollments, progress, and certifications.</li>
      <li><b>Payment Information:</b> For purchases and subscriptions (processed securely through third-party providers).</li>
      <li><b>Usage Data:</b> Browser type, IP address, device information, and website activity for analytics.</li>
    </ul>

    <h6>2. How We Use Your Information</h6>
    <p>We use your information for purposes such as:</p>
    <ul>
      <li>Providing and managing the services on our platform.</li>
      <li>Personalizing user experiences and offering relevant content.</li>
      <li>Processing payments and managing subscriptions.</li>
      <li>Communicating with users for support, updates, or marketing (with consent).</li>
      <li>Improving our platform and analyzing usage trends.</li>
    </ul>

    <h6>3. Sharing of Information</h6>
    <p>We may share your information in the following situations:</p>
    <ul>
      <li>With trusted third-party service providers for processing payments, hosting, or analytics.</li>
      <li>As required by law or to protect our legal rights.</li>
      <li>With your consent, for specific purposes like collaboration between students and tutors.</li>
    </ul>

    <h6>4. Data Security</h6>
    <p>We prioritize protecting your information by implementing industry-standard measures, including:</p>
    <ul>
      <li>Secure socket layer (SSL) technology.</li>
      <li>Regular security assessments.</li>
      <li>Restricted access to personal data.</li>
    </ul>

    <h6>5. Cookies and Tracking Technologies</h6>
    <p>We use cookies to:</p>
    <ul>
      <li>Enhance user experience.</li>
      <li>Track and analyze usage for improvements.</li>
    </ul>
    <p>You can control cookies through your browser settings.</p>

    <h6>6. User Rights</h6>
    <p>As a user, you have the right to:</p>
    <ul>
      <li>Access, correct, or delete your personal information.</li>
      <li>Opt-out of certain data uses, including marketing communications.</li>
      <li>Lodge a complaint with a data protection authority if you believe your rights have been violated.</li>
    </ul>

    <h6>7. Third-Party Links</h6>
    <p>Our website may contain links to external websites. We are not responsible for their content or privacy practices.</p>

    <h6>8. Changes to this Privacy Policy</h6>
    <p>We may update this Privacy Policy from time to time. Updates will be reflected on this page, and we encourage you to review it periodically.</p>

    <h6>9. Contact Us</h6>
    <p>For questions or concerns about this Privacy Policy, contact us at:</p>
    <ul>
      <li><b>Email:</b>guruathome24.online@gmail.com</li>
      <li><b>Address:</b> Jaipur, Rajasthan</li>
    </ul>
  `;

  return (
    <>
      <section className={`${st.pageContent}`}>
        <div className={`container ${st.leftContent} ${st.marginTopp}`}>
          <div className={`container ${cx.contentBody} mt-3`}>
            <Form>
              <Form.Group className={`${st.formField}`} controlId="">
                <h5 className="mb-5">Privacy Policy</h5>
                <div
                  className={`${st.fontColor} text-left mt-2 mb-3`}
                  style={{ fontSize: "15px" }}
                  dangerouslySetInnerHTML={{ __html: data }}
                />
              </Form.Group>
            </Form>
          </div>
        </div>
      </section>
    </>
  );
}
