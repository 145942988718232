import React, { useState } from "react";
import st from "../../../style.module.scss";
import cx from "./PlanSeclect.module.scss";
import { useLocation, useHistory } from "react-router-dom";
import { Col, Row, Form, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Student/Footer/Footer";
import { FaTimes } from "react-icons/fa"

export default function PlanSeclect() {
  const location: any = useLocation();
  const navigate = useHistory();

  const [currency, setCurrency] = useState<any>(location?.state?.location?.state?.plans[0]?.Planfrequencys)
  const [plans, setPlans] = useState<any>(location?.state?.location?.state?.plans)
  const [description, setDescription] = useState<string>("")
  const [selectCurr, setselectCurr] = useState<any>(location?.state?.location?.state?.plans[0]?.Planfrequencys[0])
  const [secondCurr, setsecondCurr] = useState<any>(location?.state?.location?.state?.plans[1]?.Planfrequencys[0])
  const [thirdCurr, setthirdCurr] = useState<any>(location?.state?.location?.state?.plans[2]?.Planfrequencys[0])

  const descOne = location?.state?.location?.state?.plans[0]?.description;
  const descTwo = location?.state?.location?.state?.plans[1]?.description;
  const descThree = location?.state?.location?.state?.plans[2]?.description;

  const createMarkup = (htmlString: any) => {
    return { __html: htmlString };
  };

  return (
    <>
      <Header />
      <section className={`${st.pageContent} ${cx.planSelectPage}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.marginTop}`}></div>
          <div className={`${cx.planselect}`}>
            <Container className={`${cx.mobilePadding}`}>
              <FaTimes onClick={() => navigate.goBack()} />
              <Row>
                <Col lg={4} md={6} sm={12}>
                  <div className={`${cx.planselectBox}`}>
                    <div className={`${cx.dropDOwnSelect}`}>
                      <label className="mt-3">{plans?.[0]?.title}</label>
                      <Form.Select className={`${cx.mobileSHow}`} defaultValue={plans[0]?.title}
                        onChange={(e: any) => {
                          setDescription((JSON.parse(e.target.value)).description);
                          (JSON.parse(e?.target?.value).Planfrequencys).map((item: any) => {
                            setCurrency(JSON.parse(e.target.value).Planfrequencys)
                          })
                        }}>
                        {plans?.map((item: any) => {
                          return (
                            <option value={JSON.stringify(item)}>{item?.title}</option>
                          )
                        })}
                      </Form.Select>
                    </div>

                    <div className={`${cx.textAreaAdmin}`}>
                      <textarea style={{ resize: 'none', height: '300px' }} className={`form-select`} />
                      <div className={`${cx.areaContentTop}`} dangerouslySetInnerHTML={createMarkup(descOne)} />
                    </div>


                    <div className={`${cx.dropDOwnSelect}`}>
                      <label className="mt-3">Price Per Session/Per Hour</label>
                      <Form.Select onChange={(e: any) => {
                        setselectCurr(JSON.parse(e.target.value))
                      }}>
                        {
                          currency.map((item: any) => {
                            return (
                              <>
                                <option value={JSON.stringify(item)}>{item?.currency}</option>
                              </>
                            )
                          })
                        }
                      </Form.Select>
                    </div>

                    <div className={`${cx.planList}`}>
                      <ul>
                        <li>
                          <span>0-4 Session:</span>
                          <input type="text" value={selectCurr?.one_session} className={`form-control`} />
                        </li>
                        <li>
                          <span>5-9 Session:</span>
                          <input type="text" value={selectCurr?.second_session} className={`form-control`} />
                        </li>
                        <li>
                          <span>10-15 Session:</span>
                          <input type="text" value={selectCurr?.third_session} className={`form-control`} />
                        </li>
                        <li>
                          <span>16-24 Session:</span>
                          <input type="text" value={selectCurr?.four_session} className={`form-control`} />
                        </li>
                        <li>
                          <span>25-50 Session:</span>
                          <input type="text" value={selectCurr?.five_session} className={`form-control`} />
                        </li>
                        <li>
                          <span>51 or more Session:</span>
                          <input type="text" value={selectCurr?.six_session} className={`form-control`} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>

                <Col lg={4} md={6} sm={12} className={`${cx.webShow}`}>
                  <div className={`${cx.planselectBox}`}>
                    <div className={`${cx.dropDOwnSelect}`}>
                      <label className="mt-3">{plans?.[1]?.title}</label>
                    </div>

                    <div className={`${cx.textAreaAdmin}`}>
                      <textarea style={{ resize: 'none', height: '300px' }} className={`form-select`} />
                      <div className={`${cx.areaContentTop}`} dangerouslySetInnerHTML={createMarkup(descTwo)} />
                    </div>

                    <div className={`${cx.dropDOwnSelect}`}>
                      <label className="mt-3">Price Per Session/Per Hour</label>
                      <Form.Select defaultValue={secondCurr} onChange={(e) => {
                        setsecondCurr(JSON.parse(e.target.value))
                      }}>
                        {
                          plans[1].Planfrequencys.map((item: any) => {
                            return (
                              <option value={JSON.stringify(item)}>{item?.currency}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </div>

                    <div className={`${cx.planList}`}>
                      <ul>
                        <li>
                          <span>0-4 Session:</span>
                          <input type="text" value={secondCurr?.one_session} className={`form-control`} />
                        </li>
                        <li>
                          <span>5-9 Session:</span>
                          <input type="text" value={secondCurr?.second_session} className={`form-control`} />
                        </li>
                        <li>
                          <span>10-15 Session:</span>
                          <input type="text" value={secondCurr?.third_session} className={`form-control`} />
                        </li>
                        <li>
                          <span>16-24 Session:</span>
                          <input type="text" value={secondCurr?.four_session} className={`form-control`} />
                        </li>
                        <li>
                          <span>25-50 Session:</span>
                          <input type="text" value={secondCurr?.five_session} className={`form-control`} />
                        </li>
                        <li>
                          <span>51 or more Session:</span>
                          <input type="text" value={secondCurr?.six_session} className={`form-control`} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>

                <Col lg={4} md={6} sm={12} className={`${cx.webShow}`}>
                  <div className={`${cx.planselectBox}`}>
                    <div className={`${cx.dropDOwnSelect}`}>
                      <label className="mt-3">{plans?.[2]?.title}</label>
                      <Form.Select defaultValue={thirdCurr} className={`${cx.mobileSHow}`}>
                        <option>Select Plan</option>
                      </Form.Select>
                    </div>

                    <div className={`${cx.textAreaAdmin}`}>
                      <textarea style={{ resize: 'none', height: '300px' }} className={`form-select`} />
                      <div className={`${cx.areaContentTop}`} dangerouslySetInnerHTML={createMarkup(descThree)} />
                    </div>

                    <div className={`${cx.dropDOwnSelect}`}>
                      <label className="mt-3">Price Per Session/Per Hour</label>
                      <Form.Select onChange={(e) => {
                        setthirdCurr(JSON.parse(e.target.value))
                      }}>
                        {
                          plans[2]?.Planfrequencys?.map((item: any) => {
                            return (
                              <option value={JSON.stringify(item)}>{item?.currency}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </div>

                    <div className={`${cx.planList}`}>
                      <ul>
                        <li>
                          <span>0-4 Session:</span>
                          <input type="text" value={thirdCurr?.one_session} className={`form-control`} />
                        </li>
                        <li>
                          <span>5-9 Session:</span>
                          <input type="text" value={thirdCurr?.second_session} className={`form-control`} />
                        </li>
                        <li>
                          <span>10-15 Session:</span>
                          <input type="text" value={thirdCurr?.third_session} className={`form-control`} />
                        </li>
                        <li>
                          <span>16-24 Session:</span>
                          <input type="text" value={thirdCurr?.four_session} className={`form-control`} />
                        </li>
                        <li>
                          <span>25-50 Session:</span>
                          <input type="text" value={thirdCurr?.five_session} className={`form-control`} />
                        </li>
                        <li>
                          <span>51 or more Session:</span>
                          <input type="text" value={thirdCurr?.six_session} className={`form-control`} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          <div className={`pt-3 ${st.spaceBox}`}></div>
        </div>
      </section >
      <Footer />
    </>
  );
}