import React, { useState, useEffect } from "react";
import st from "../../../style.module.scss";
import cx from "./Apply.module.scss";
import axios from 'axios';
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import { Button } from "react-bootstrap";
import { MdNotifications } from "react-icons/md";
import { FaWallet } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Bottombar from "../../../components/Teacher/Bottombar/Bottombar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Teacher/Footer/Footer";
import { RotatingLines } from "react-loader-spinner";

export default function Teacherapply() {
  const location: any = useLocation();
  let localData = JSON.parse(localStorage.getItem("userData")!);
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 1000);
    }
  }, [onetimenotify])

  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const navigate = useHistory();
  const [loadingSpinner, setLoadingSpinner] = useState<any>('Apply')
  let [disablebtn, setDisablebtn] = useState<any>(false)
  const [timeDate, settimeDate] = useState(location?.state?.time)
  const [data, setData] = useState<any>([
    {
      select_date: "", utc_select_time: "", utc_select_end_time: ""
    },
    {
      select_date: "", utc_select_time: "", utc_select_end_time: ""
    },
    {
      select_date: "", utc_select_time: "", utc_select_end_time: ""
    },
  ])

  // ---------------------------APPLY FOR DEMO CLASSES------------------------------ //
  function applyDemoClass() {

    let formdata = new FormData();
    formdata.append('studentapplyfordemo_id', location?.state?.item?.student_preference_detail[0]?.studentapplyfordemo_id);
    formdata.append('teacher_id', localData?.id);
    formdata.append('student_id', location?.state?.item?.user_id);
    formdata.append('data', JSON.stringify(data));

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/apply_for_demo_class`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    setDisablebtn(true)
    setLoadingSpinner(
      <RotatingLines
        strokeColor="white"
        strokeWidth="5"
        animationDuration="0.75"
        width="25"
        visible={true}
      />)
    axios(config)
      .then(function (response) {
        // settimeDate(location?.state?.item?.student_preference);
        setData(response.data.data);
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.push({
              pathname: '/teacher/home',
              state: {
                detail: response.data.id,
              }
            });
          }, 2000);
        }
        else {
          setTimenotify(response.data.message);
          setOnetimenotify(true)
          setLoadingSpinner('Apply')
          setDisablebtn(false)
        }
      })
      .catch(function (error) {
        notify("Please select at least one slot");
        setLoadingSpinner('Apply')
        setDisablebtn(false)
        setTimenotify(error.response.data.message);
      });
  }

  // AM PM FUNCTION
  function tConvert(time: any) {
    // Check correct time format and split into components 
    time = time?.toString()?.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) { // If time format correct
      time = time?.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join('');
  }

  return (
    <>
      <Header />
      <ToastContainer limit={1} />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.titleBar} ${st.fixHeader}`}>
              <div className={`${cx.searchBox}`}>
                <BiSearch className={`${cx.icon}`} />
                <input type="text" className="form-control" placeholder="Search" />
              </div>
              <NavLink to="/teacher/notification" className={`${cx.notification}`}>
                <MdNotifications />
                {/* <span>2</span> */}
              </NavLink>
              <NavLink to="/teacher/wallet" className={`${cx.wallet}`}>
                <FaWallet />
              </NavLink>
            </div>
          </div>
          <div className={`${cx.spaceBox}`}></div>

          <div className={`container ${cx.checkMargin}`}>

            {timeDate?.map((item: any, index: number) => {
              return (
                <div className={`${cx.checkBox}`}>
                  <input type="checkbox"
                    onChange={(e: any) => {
                      if (e.target.checked === true) {
                        setData((prev: any) => {
                          prev[index].select_date = location?.state?.item?.student_preference[index]?.utc_select_date
                          prev[index].utc_select_time = location?.state?.item?.student_preference[index]?.utc_select_time
                          prev[index].utc_select_end_time = location?.state?.item?.student_preference[index]?.utc_select_end_time
                          return prev
                        })
                      } else {
                        setData((prev: any) => {
                          prev[index].select_date = ""
                          prev[index].utc_select_time = ""
                          prev[index].utc_select_end_time = ""
                          return prev
                        })
                      }
                    }} />
                  <ul>
                    <li>
                      <span><h5>Preference</h5></span>
                    </li>
                    <li>
                      <span>Time - {tConvert(item?.time)}</span>
                      <span>Date - {item?.date}</span>
                    </li>
                  </ul>
                </div>
              )
            })}
            {disablebtn === true ? <Button disabled className={`${cx.applyBtn}`}
              onClick={applyDemoClass}>
              {loadingSpinner}
            </Button> : <Button className={`${cx.applyBtn}`}
              onClick={applyDemoClass}>
              {loadingSpinner}
            </Button>}
          </div>
          <div className={`pt-3 ${st.spaceBox}`}></div>
          <Bottombar />
        </div>
      </section >
      <Footer />
    </>
  );
}