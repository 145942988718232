import React, { useState, useEffect } from "react";
import axios from 'axios';
import st from "../../../style.module.scss";
import cx from "./Aboutus.module.scss";
import { NavLink } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { MdArrowBackIos } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";
import bg from "../../../images/bg1.svg";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Student/Footer/Footer";
import ReactLoading from "react-loading";

export default function Aboutus() {

  const [data, setData] = useState<any>({});
  useEffect(() => {
    aboutUs();
  }, [])

  const [loaderStatus, setloaderStatus] = useState(
    <ReactLoading type="cylon" color="blue" />
  );

  // function removeTags(str: any) {
  //   if ((str === null) || (str === '') || (str === undefined))
  //     return false;
  //   else
  //     str = str.toString();
  //   return str.replace(/(<([^>]+)>)/ig, '');
  // }

  // -------------------------------------------- ABOUT US API ------------------------------------------------//
  function aboutUs() {
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/aboutUsPrivacy_PolicyTerms_Condition`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
    };
    axios(config)
      .then(function (response) {
        if (response.data.assign_by_admin) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
        setData(response.data.data)
      })
      .catch(function (error) {
        // notify(error?.response?.data?.message);
        // setTimenotify(error.response.data.message);
      });
  }

  return (
    <>
      <Header />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.sticky}`}>
              <Row className={`${cx.titleBar}`}>
                <Col className={`col-2`}>
                  <NavLink to="/student/profile" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col className={`col-8 ${cx.aboutUs}`}>
                  <h6>About us</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
            <img src={bg} className={`${cx.bgShape}`} />
          </div>
          <div className={`container ${cx.contentBody} mt-3`}>
            <Form>
              {data?.length == 0 && (
                <div className="d-flex justify-content-center">
                  {loaderStatus}
                </div>
              )}
              <Form.Group className={`${st.formField}`} controlId="">
                <h5 className="mb-3">About Us</h5>
                <div
                  className={`${st.fontColor} text-left mt-2 mb-3`}
                  style={{ fontSize: "15px" }}
                  dangerouslySetInnerHTML={{ __html: data?.about_us }}
                />
              </Form.Group>
            </Form>
          </div>
        </div>
        {/* <Rightbody /> */}
      </section>
      <Footer />
    </>
  );
}