import React, { useEffect, useState } from "react";
import axios from 'axios';
import st from "../../../style.module.scss";
import cx from "./Privacypolicy.module.scss";
import { NavLink } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { MdArrowBackIos } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";
import bg from "../../../images/bg1.svg";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Student/Footer/Footer";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from "react-loading";

export default function Privacypolicy() {
  useEffect(() => {
    privacyPolicy();
  }, [])
  const [data, setData] = useState<any>({});

  const [loaderStatus, setloaderStatus] = useState(
    <ReactLoading type="cylon" color="blue" />
  );

  // -------------------------------------------- PRIVACY POLICY API ------------------------------------------------//
  function privacyPolicy() {

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/aboutUsPrivacy_PolicyTerms_Condition`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
    };
    axios(config)
      .then(function (response) {
        if (response.data.data) {
          setloaderStatus(<div>Data not Found...</div>);
        }
        setData(response.data.data)
      })
      .catch(function (error) {
        console.log(error)
        toast.error("Something went wrong");
      });
  }

  return (
    <>
      <Header />
      <section className={`${st.pageContent}`}>
        <div className={` container ${st.leftContent} ${st.marginTopp}`}>
          <div className={`  ${cx.headerTitle}`}>
            <div className={`${cx.sticky}`}>
              <Row className={`${cx.titleBar}`}>
                <Col className={`col-2`}>
                  <NavLink to="/student/profile" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col className={`col-8 ${cx.aboutUs}`}>
                  <h6>Privacy Policy</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
            <img src={bg} className={`${cx.bgShape}`} />
          </div>
          <div className={`container ${cx.contentBody} mt-3`}>
            {
              data?.privacy_policy?.length == 0 ? (
                <div className="d-flex justify-content-center">
                  {loaderStatus}
                </div>
              ) :
                <Form>
                  <Form.Group className={`${st.formField}`} controlId="">
                    <h5 className="mb-3">Privacy Policy</h5>
                    <div
                      className={`${st.fontColor} text-left mt-2 mb-3`}
                      style={{ fontSize: "15px" }}
                      dangerouslySetInnerHTML={{ __html: data?.privacy_policy }}
                    />
                  </Form.Group>
                </Form>
            }
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}