import React, { useState, useEffect } from "react";
import st from "../../../style.module.scss";
import axios from 'axios';
import cx from "./Editprofile.module.scss";
import { NavLink, useHistory } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { MdArrowBackIos } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";
import bg from "../../../images/bg-img-new.svg";
import edit from "../../../images/edit-img.svg";
import user from "../../../images/userLogo.jpeg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import TimezoneSelect, { allTimezones } from 'react-timezone-select';
import 'react-phone-input-2/lib/style.css';
import Select, { ActionMeta } from 'react-select';
import { RotatingLines } from "react-loader-spinner";
import Avatar from 'react-avatar';
import ProfileAvatar from "../../../Custom_Components/ProfileAvatar/ProfileAvatar";

export default function Editprofile(props: any) {

  let localData = JSON.parse(localStorage.getItem("userData")!);
  //---react-toastify---//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const navigate = useHistory();


  //------------------------------ STUDENT EDIT PROFILE API ----------------------------//
  const [userid] = useState(localData.id);
  const [email, setEmail] = useState(localData.email);
  const [name, setName] = useState(localData.name);
  const [parent_name, setparent_name] = useState(localData.parent_name);
  const [phone, setphone] = useState(localData?.phone);
  const [class_id, setClass_id] = useState(localData?.class_id);
  const [subject_id, setSubject_id] = useState<any>([]);
  const [address, setAddress] = useState(localData.address);
  const [timezone, setTimezone] = useState(localData.timezone);
  const [currency, setCurrency] = useState(localData.currency)
  const [DialCode, setDialCode] = useState(localData?.dial_code);
  const [image, setImage] = useState<any>(localData?.image);
  const [pic, setPic] = useState<any>("")
  const [abbrev, setAbbrev] = useState('');
  const [course_id, setCourse_id] = useState<any>([]);
  const [category_id, setcategory_id] = useState<any>([])
  const [loadingSpinner, setLoadingSpinner] = useState<any>('Submit')

  // const handleChange7 = (e: any) => {
  //   const result = e.target.value.replace(/[^A-Za-z ]/ig, '')
  //   setMessage(result);
  // };

  // function phoneInput(value: any, data: any) {
  //   setphone(value);
  //   setDialCode(`${data.dialCode}`);
  // }

  function phoneInput(value: any, data: CountryData) {
    const dialCode = `+${data.dialCode}`;
    const mobileNumber = value?.substring(data?.dialCode?.length);
    setphone(mobileNumber)
    setDialCode(dialCode)
  }

  // ------------------------------------------- CLASSS DETAILS ------------------------------------------- //
  // const [type, setType] = useState("1");
  const [subjectorcourses, setsubjectorcourses] = useState<any>([]);
  const [classData, setClassData] = useState<any>([]);

  useEffect(() => {
    getallclscategory();
  }, [])

  let formdata = new FormData();
  // ----------------------------- GET ALL CLASS CATEGORY API------------------------------ //
  function getallclscategory() {
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/get_all_class_subject_categories_course`,
      headers: {
        "Authorization": "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };

    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          // GET ALL CATEGORY AND COURSES IN DROPDOWN
          let classAndSubjects: any[] = [];
          response.data.data?.classDetails?.forEach((subelement: any, index: number) => {
            classAndSubjects.push({ extraData: subelement?.subjectDetails, label: subelement?.class_name, value: `${subelement?.id + response.data.data.categoryDetails.length}`, uniqueId: subelement?.id, type: 'class' })
          });
          response.data.data?.categoryDetails?.forEach((subelement: any, index: number) => {
            classAndSubjects.push({ extraData: subelement?.courseDetails, label: subelement?.name, value: `${subelement?.id + response.data.data.categoryDetails.length}`, uniqueId: subelement?.id, type: 'category' })
          });
          setClassData(classAndSubjects);
          defaultVal(localData?.class_id ? localData?.subject_id : localData?.course_id, localData?.subject_id ? localData?.class_id : localData?.category_id, classAndSubjects, localData?.category_id ? "category" : "class")
        }
        else {
          setTimenotify(response.data.message);
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
      });
  }
  // function clearSubjectandCourses() {
  //   setsubjectorcourses([])
  // }

  function handleClassCategoryStatus(eventclass: any, action: ActionMeta<Object>) {
    if (eventclass?.type === "class") {
      setClass_id(eventclass?.uniqueId);
      setcategory_id("");
      setSubject_id([]);
      setCourse_id([]);
    } else {
      setcategory_id(eventclass?.uniqueId);
      setClass_id("");
      setSubject_id([]);
      setCourse_id([]);
    }

    if (eventclass?.type == "categories") {
      let extraData: any = []
      // setType("2");
      setsubjectorcourses(extraData)
      eventclass?.extraData.forEach((course: any) => {
        extraData?.push({ label: course?.name, value: course?.id })
      });
    } else {
      let extraData: any = []
      // setType("1");
      setsubjectorcourses(extraData)
      eventclass?.extraData?.forEach((subject: any) => {
        extraData?.push({ label: subject?.title || subject?.name, value: subject?.id })
      });
    }
  }

  function handleSubjectCoursesStatus(event: any) {
    //console.log(event, "event")
    if (class_id == "") {
      setCourse_id(event)
      setSubject_id([])
    } else {
      setSubject_id(event)
      setCourse_id([])
    }
  }

  // ---------------------------- EDIT PROFILE API -------------------------- //
  function editstudentnew(valueSubject: any) {
    let formdata = new FormData();
    formdata.append('userId', userid);
    formdata.append('email', email);
    formdata.append('name', name);
    formdata.append('parent_name', parent_name);
    formdata.append("phone", phone !== "" ? phone : localData?.phone);
    formdata.append('class_id', class_id.toString());
    formdata.append('category_id', category_id.toString());
    formdata.append('subject_id', class_id ? JSON.stringify(valueSubject) : "");
    formdata.append('course_id', category_id ? JSON.stringify(course_id) : "");
    formdata.append('address', address);
    formdata.append('abbrev', abbrev);
    formdata.append('currency', currency);
    formdata.append('timezone', timezone);
    formdata.append('image', pic == "" ? image : pic[0]);
    formdata.append("dial_code", DialCode !== "" ? DialCode : localData?.dialCode);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/studentupdateProfile`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token")!,
      },
      data: formdata,
    };
    setLoadingSpinner(
      <RotatingLines
        strokeColor="white"
        strokeWidth="5"
        animationDuration="0.75"
        width="25"
        visible={true}
      />)
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          localStorage.setItem("userData", JSON.stringify(response.data.data))
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.push({
              pathname: '/student/profile',
              state: { detail: response.data.id }
            });
          }, 2000);
        }
        else {
          setLoadingSpinner('Submit')
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
      });
  }


  function defaultVal(subjectID: any, classID: any, defaultclassData: any, type: string) {
    if (type === "category") {
      let splitSubjectID = subjectID?.split(",");
      const course_id = defaultclassData?.find((item: any) => item?.uniqueId === +classID)?.extraData?.filter((item: any) =>
        splitSubjectID?.includes(item?.id?.toString())
      ).map((e: any) => {
        return { label: e?.name, value: e?.id }
      }) || []
      setCourse_id(course_id)
      return course_id
    } else {
      let splitSubjectID = subjectID?.split(",");
      const subject_id = defaultclassData?.find((item: any) => item?.uniqueId === classID)?.extraData?.filter((item: any) =>
        splitSubjectID?.includes(item?.id?.toString())
      ).map((e: any) => {
        return { label: e?.title, value: e?.id }
      }) || []
      setSubject_id(subject_id)
      return subject_id
    }

  }

  return (
    <>
      <ToastContainer limit={1} />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent} ${st.marginTopp}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.sticky}`}>
              <Row className={`${cx.titleBar}`}>
                <Col className={`col-2`}>
                  <NavLink to="/student/profile" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col className={`col-8 ${cx.aboutUs}`}>
                  <h6>Edit Profile</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
            <img src={bg} className={`${cx.bgShape}`} />
          </div>

          <div className={`${cx.contentBody} mt-3`}>

            <Col lg={11} className={'m-auto'}>
              <Form className={`${cx.paddingLeft} ${st.formFull}`}>
                <Row className="align-items-center">
                  <Col className={`col-12 ${cx.profileText}`}>
                    <div className={`${cx.userView}`}>
                      {image == "" || image == null ?
                        <ProfileAvatar name={name} />
                        : <img src={image} className={`${cx.userImg}`} />}
                      <i className={`${cx.editIcon}`}>
                        <img src={edit} className={`${cx.editImg}`} />
                        <input type="file"
                          onChange={(e: any) => {
                            setPic(e.target.files)
                            setImage(URL.createObjectURL(e.target.files[0]))
                          }} /></i>
                    </div>
                  </Col>

                  <Col className="col-lg-6 col-12">
                    <div className={`${cx.profileEdit}`}>
                      <label htmlFor="">Student Name</label>
                      <input type="text"
                        defaultValue={localData.name}
                        key={props?.name}
                        onChange={(e) => {
                          setName(e.target.value)
                        }}
                      />
                    </div>
                  </Col>

                  <Col className="col-lg-6 col-12">
                    <div className={`${cx.profileEdit}`}>
                      <label htmlFor="">Parent Name</label>
                      <input type="text" defaultValue={localData.parent_name}
                        key={props?.parent_name}
                        onChange={(e) => {
                          setparent_name(e.target.value)
                        }}
                      />
                    </div>
                  </Col>

                  <Col className="col-lg-6 col-12">
                    <div className={`${cx.profileEdit}`}>
                      <label htmlFor="">Email</label>
                      <input type="text" value={localData.email}
                        // defaultValue={props?.email}
                        key={props?.email}
                        onChange={(e) => {
                          setEmail(e.target.value)
                        }}
                      />
                    </div>
                  </Col>

                  <Col className="col-lg-6 col-12">
                    <div className={`${cx.profileEdit}`}>
                      <label htmlFor="">Phone Number</label>
                      <PhoneInput
                        country={DialCode} // You can set a default country code
                        value={`${DialCode}${phone}`} // Combine dial code and phone number
                        onChange={phoneInput} // Handle changes
                        inputProps={{
                          name: 'phone',
                          required: true,
                          autoFocus: true,
                        }}
                      />
                    </div>
                  </Col>

                  {classData.length > 0 && <Col className="col-lg-6 col-12">
                    <div className={`${cx.profileEdit}`}>
                      <label htmlFor="">Select Class/ Category</label>
                      <Form.Group className={`${st.formField}`} controlId="">
                        {/* <label htmlFor="">Select Class</label> */}
                        <Select
                          defaultValue={classData.find((item: any) => item?.uniqueId == (localData?.category_id ? localData?.category_id : localData?.class_id))}
                          key={classData.find((item: any) => item?.uniqueId == (localData?.category_id ? localData?.category_id : localData?.class_id))?.uniqueId}
                          onChange={handleClassCategoryStatus}
                          options={classData}
                        />
                      </Form.Group>
                    </div>
                  </Col>}

                  <Col className="col-lg-6 col-12">
                    <div className={`${cx.profileEdit}`}>
                      <label htmlFor="">Select Subject/ Course</label>
                      <Form.Group className={`${st.formField}`} controlId="">
                        <Select isMulti
                          isClearable
                          value={subject_id.length > 0 ? subject_id : course_id}
                          id="subject_id"
                          onChange={handleSubjectCoursesStatus}
                          options={subjectorcourses}
                          key={subject_id.length > 0 ? subject_id?.map((item: any) => item.value) : course_id?.map((item: any) => item.value)}
                        />
                      </Form.Group>
                    </div>
                  </Col>

                  <Col className="col-lg-6 col-12">
                    <div className={`${cx.profileEdit}`}>
                      <label htmlFor="">Timezone</label>
                      <TimezoneSelect
                        value={timezone}
                        // labelStyle="abbrev"
                        onChange={(e: any) => {
                          setTimezone(e?.value)
                          setAbbrev(e?.abbrev)
                        }}
                        timezones={{
                          ...allTimezones,
                          'Asia/Kolkata': 'IST (Indian Standard Time)',
                          'Asia/Dubai': 'GST (Gulf Time)',
                          'America/Los_Angeles': 'PST/PDT(USA - Pacific Time)',
                          'America/Detroit': 'EST/EDT(USA - Eastern Time)',
                          'America/Chicago': 'CST/CDT (USA - Central Time)',
                          'America/Boise': 'MST/MDT(USA -  Mountain Time)',
                          'Asia/Kuala_Lumpur': 'SGT(Singapore Time)',
                          'Europe/London': "BST/GMT"
                        }} />
                    </div>
                  </Col>

                  <Col className="col-lg-6 col-12">
                    <div className={`${cx.profileEdit}`}>
                      <label htmlFor="">Currency</label>
                      <Form.Select aria-label="Default select example"
                        defaultValue={localData.currency}
                        key={props?.currency}
                        onChange={(e) => {
                          setCurrency(e.target.value)
                        }}>
                        <option value=""> Select Currency</option>
                        <option value="INR-₹">INR- ₹</option>
                        <option value="USD-$">USD- $</option>
                        <option value="AUD-$">AUD- $</option>
                        <option value="CAD-$">CAD- $</option>
                        <option value="EUR-€">EUR- €</option>
                        <option value="NZD-$">NZD- $</option>
                        <option value="GBP-£">GBP- £</option>
                      </Form.Select>
                    </div>
                  </Col>

                  <Col className="col-12">
                    <div className={`${cx.profileEdit}`}>
                      <label htmlFor="">Address </label>
                      <textarea name="" placeholder="Address"
                        defaultValue={localData.address}
                        key={props?.address}
                        onChange={(e) => {
                          setAddress(e.target.value)
                        }}>
                      </textarea>
                    </div>
                  </Col>

                  <Col className="col-12">
                    <NavLink to="#" className={`btn ${cx.SubmitBtn}`}
                      onClick={() => {
                        editstudentnew(subject_id)
                      }}>
                      {loadingSpinner}
                    </NavLink>
                  </Col>

                </Row>
              </Form>
            </Col>
          </div>
        </div>
      </section>
    </>
  );
}